import React, { useState, useEffect, useCallback } from 'react';
import { useHistory, useParams } from 'react-router';

import './movie-grid.scss';

import MovieCard from '../movie-card/MovieCard';
import Button, { OutlineButton } from '../button/Button';
import Input from '../input/Input';

import tmdbApi, { category, movieType, tvType } from '../../api/tmdbApi';

const MovieGrid = (props) => {
    const [items, setItems] = useState([]);
    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState(0);

    const { keyword } = useParams();

    useEffect(() => {
        const getList = async () => {
            try {
                const params = { query: keyword || '', page: 1 };
                let response = keyword
                    ? await tmdbApi.search(props.category, { params })
                    : props.category === category.movie
                    ? await tmdbApi.getMoviesList(movieType.upcoming, { params })
                    : await tmdbApi.getTvList(tvType.popular, { params });

                // Filter results to only include titles starting with the keyword
                if (keyword) {
                    response.results = response.results.filter((item) =>
                        item.title
                            ? item.title.toLowerCase().startsWith(keyword.toLowerCase())
                            : item.name.toLowerCase().startsWith(keyword.toLowerCase())
                    );
                }

                setItems(response.results);
                setTotalPage(response.total_pages);
                setPage(1);
            } catch (error) {
                console.error('Error fetching movie list:', error);
            }
        };
        getList();
    }, [props.category, keyword]);

    const loadMore = async () => {
        try {
            const params = { query: keyword || '', page: page + 1 };
            let response = keyword
                ? await tmdbApi.search(props.category, { params })
                : props.category === category.movie
                ? await tmdbApi.getMoviesList(movieType.upcoming, { params })
                : await tmdbApi.getTvList(tvType.popular, { params });

            // Filter results to only include titles starting with the keyword
            if (keyword) {
                response.results = response.results.filter((item) =>
                    item.title
                        ? item.title.toLowerCase().startsWith(keyword.toLowerCase())
                        : item.name.toLowerCase().startsWith(keyword.toLowerCase())
                );
            }

            setItems([...items, ...response.results]);
            setPage(page + 1);
        } catch (error) {
            console.error('Error loading more movies:', error);
        }
    };

    return (
        <>
            <div className="section mb-3">
                <MovieSearch category={props.category} initialKeyword={keyword} />
            </div>
            <div className="movie-grid">
                {items.map((item, i) => (
                    <MovieCard category={props.category} item={item} key={i} />
                ))}
            </div>
            {page < totalPage && (
                <div className="movie-grid__loadmore">
                    <OutlineButton className="small" onClick={loadMore}>
                        Load more
                    </OutlineButton>
                </div>
            )}
        </>
    );
};

const MovieSearch = ({ category, initialKeyword }) => {
    const history = useHistory();
    const [keyword, setKeyword] = useState(initialKeyword || '');
    const [suggestions, setSuggestions] = useState([]);

    const fetchSuggestions = useCallback(async (query) => {
        if (query.trim().length === 0) {
            setSuggestions([]);
            return;
        }
        try {
            const response = await tmdbApi.search(category, { params: { query, page: 1 } });
            const filteredSuggestions = response.results.filter((item) =>
                item.title
                    ? item.title.toLowerCase().startsWith(query.toLowerCase())
                    : item.name.toLowerCase().startsWith(query.toLowerCase())
            );
            setSuggestions(filteredSuggestions);
        } catch (error) {
            console.error('Error fetching suggestions:', error);
        }
    }, [category]);

    const goToSearch = useCallback(() => {
        if (keyword.trim().length > 0) {
            history.push(`/${category}/search/${keyword}`);
        }
    }, [keyword, category, history]);

    useEffect(() => {
        const handleKeyUp = (e) => {
            if (e.key === 'Enter') {
                goToSearch();
            }
        };
        document.addEventListener('keyup', handleKeyUp);
        return () => {
            document.removeEventListener('keyup', handleKeyUp);
        };
    }, [goToSearch]);

    useEffect(() => {
        fetchSuggestions(keyword);
    }, [keyword, fetchSuggestions]);

    return (
        <div className="movie-search">
            <Input
                type="text"
                placeholder="Search for movies or TV shows..."
                value={keyword}
                onChange={(e) => setKeyword(e.target.value)}
            />
            <Button className="small" onClick={goToSearch}>
                Search
            </Button>
            {suggestions.length > 0 && (
                <div className="movie-search__suggestions">
                    <ul>
                        {suggestions.map((item, index) => (
                            <li
                                key={index}
                                onClick={() => {
                                    setKeyword(item.title || item.name);
                                    goToSearch();
                                }}
                            >
                                {item.title || item.name}
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default MovieGrid;
