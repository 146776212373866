import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';

import tmdbApi from '../../api/tmdbApi';
import apiConfig from '../../api/apiConfig';

import './detail.scss';
import CastList from './CastList';
import VideoList from './VideoList';
import MovieList from '../../components/movie-list/MovieList';

const Detail = () => {
    const { category, id } = useParams(); // Extract params from the URL
    const [item, setItem] = useState(null);
    const [showPlayer, setShowPlayer] = useState(false); // State to toggle video player

    useEffect(() => {
        const getDetail = async () => {
            try {
                const response = await tmdbApi.detail(category, id, { params: {} });
                setItem(response);
                window.scrollTo(0, 0); // Scroll to the top
            } catch (error) {
                console.error("Error fetching details:", error);
            }
        };
        getDetail();
    }, [category, id]);

    return (
        <>
            {item && (
                <>
                    {/* Banner Section */}
                    <div
                        className="banner"
                        style={{
                            backgroundImage: `url(${apiConfig.originalImage(
                                item.backdrop_path || item.poster_path
                            )})`,
                        }}
                    ></div>

                    {/* Movie Content */}
                    <div className="mb-3 movie-content container">
                        <div className="movie-content__poster">
                            <div
                                className="movie-content__poster__img"
                                style={{
                                    backgroundImage: `url(${apiConfig.originalImage(
                                        item.poster_path || item.backdrop_path
                                    )})`,
                                }}
                            ></div>
                        </div>
                        <div className="movie-content__info">
                            <h1 className="title">{item.title || item.name}</h1>

                            {/* Genres Section */}
                            <div className="genres">
                                {item.genres &&
                                    item.genres.slice(0, 5).map((genre, i) => (
                                        <span key={i} className="genres__item">
                                            {genre.name}
                                        </span>
                                    ))}
                            </div>

                            {/* Watch Button */}
                            <div>
                                {!showPlayer ? (
                                    <input
                                        type="button"
                                        onClick={() => setShowPlayer(true)} // Show player on click
                                        value="Watch Now"
                                        className="watch-button"
                                    />
                                ) : (
                                    <div className="video-player">
                                        <iframe
                                            src={`https://vidsrc.dev/embed/${category}/${id}`}
                                            frameBorder="0"
                                            allowFullScreen
                                            width="100%"
                                            height="500px"
                                            title="Video Player"
                                        ></iframe>
                                    </div>
                                )}
                            </div>

                            {/* Overview */}
                            <p className="overview">{item.overview}</p>

                            {/* Cast List */}
                            <div className="cast">
                                <div className="section__header">
                                    <h2>Casts</h2>
                                </div>
                                <CastList id={item.id} />
                            </div>
                        </div>
                    </div>

                    {/* Additional Sections */}
                    <div className="container">
                        <div className="section mb-3">
                            <VideoList id={item.id} />
                        </div>
                        <div className="section mb-3">
                            <div className="section__header mb-2">
                                <h2>Similar</h2>
                            </div>
                            <MovieList category={category} type="similar" id={item.id} />
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

export default Detail;
